import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import Select from "react-select";
import Dropzone from "react-dropzone";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { post, get, put } from "helpers/apiServices";
import convertImageToBase64 from "helpers/convertToBase64";

const EditProduct = () => {
  const [productName, setProductName] = useState();
  const [productPrice, setProductPrice] = useState();
  const [productBuyingPrice, setProductBuyingPrice] = useState();
  const [productQuantity, setProductQuantity] = useState(null);
  const [productDescription, setProductDescription] = useState(null);
  const [selectedMulti, setselectedMulti] = useState([]);
  const [unit, setUnit] = useState(null);
  const navigate = useNavigate();
  const [fournisseurs, setFournisseurs] = useState([]);
  const [selectedFournisseurs, setSelectedFournisseurs] = useState([]);
  const [product, setProduct] = useState(null);
  const [extension, setExtension] = useState(null);
  const [convertedImage, setConvertedImage] = useState(null);
  let { id } = useParams();

  const [unities, setUnities] = useState([])

  //meta title
  document.title = "Modifier produit";



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get('fournisseurs/');
        if(response.status === 200){  
          let allFournisseurs = response?.data;     
          let objectTab = allFournisseurs.map((fournisseur) => ({
            value: fournisseur.id,
            label: fournisseur.fullname
          }));
          setFournisseurs(objectTab);
        }
      } catch (error) {
        console.error('Error while getting fournisseurs:', error);
        // Gérer l'erreur ici
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get('/products/unities/');
        if(response.status === 200){  
          let allUnities = response?.data;  
          let objectTab = allUnities.map((unity1) => ({
            value: unity1.id,
            label: unity1.libelle
          })); 
          setUnities(objectTab);
        }
      } catch (error) {
        console.error('Error while getting fournisseurs:', error);
        // Gérer l'erreur ici
      }
    };
    fetchData();
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get(`products/${id}`);
        setProduct(response.data);
        setProductName(response?.data?.title)
        setProductPrice(response?.data?.price)
        setProductBuyingPrice(response?.data?.prix_gros)
        setProductQuantity(response?.data?.quantity)
        setProductDescription(response?.data?.description)
        const fournisseurs1 = []
        const fournisseursID = []
        response?.data?.fournisseurs.map(fournisseur => {
          fournisseurs1.push({
            value: fournisseur.id,
            label: fournisseur.fullname
          })  
          fournisseursID.push(fournisseur.id)

        })
        setselectedMulti(fournisseurs1)
        setSelectedFournisseurs(fournisseursID)
        setUnit(response?.data?.unity?.id);
        
      let defaultUnity = null;
      for (let i = 0; i < unities.length; i++) {
        if (unities[i].value === response.data.unity?.id) {
          defaultUnity = unities[i];
          break;
        }
      }
        
        setUnit(defaultUnity);
      } catch (error) {
        console.error('Error getting products:', error);
        // Gérer l'erreur ici
      }
    };
    fetchData();
  }, [])



  const [selectedFiles, setselectedFiles] = useState([])
  

  const handleName = (e) =>{
    setProductName(e.target.value)
  }
  const handleUnit = (e) =>{
    setUnit(e)
  }
  const handleUnitprice = (e) =>{
    setProductPrice(e.target.value)
  }
  const handleBuyingPrice = (e) =>{
    setProductBuyingPrice(e.target.value)
  }
  const handleQuantity = (e) =>{
    setProductQuantity(e.target.value)
  }
  const handleDescription = (e) =>{
    setProductDescription(e.target.value)
  }

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (file){
        setImage(file)
        const image64 = await convertImageToBase64(file)
        setConvertedImage(image64)
        
        const fileName = file.name;
    
        const fileSegments = fileName.split('.');
        
        const fileExtension = fileSegments[fileSegments.length - 1];
        setExtension(fileExtension)
    }
    
  };
  
  function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti);
    const fournisseursTab = []
    selectedMulti.map(selectedfournisseur => {
      if(selectedfournisseur){
        fournisseursTab.push(selectedfournisseur?.value);
      }
    });
    setSelectedFournisseurs(fournisseursTab);
  }

  const handleProductSubmit = async (e) =>{
    e.preventDefault();
    try {
      let productInfos = null
      if(convertedImage){
        productInfos = {
          title: productName,
          description: productDescription,
          quantity: parseInt(productQuantity),
          price: parseFloat(productPrice),
          prix_gros: parseFloat(productBuyingPrice),
          unity: parseInt(unit.value),
          fournisseurs: selectedFournisseurs,
          image: {
            base64: convertedImage,
            extension: extension
          }
        }
      }else{
        
        productInfos = {
          title: productName,
          description: productDescription,
          quantity: parseInt(productQuantity),
          price: parseFloat(productPrice),
          prix_gros: parseFloat(productBuyingPrice),
          unity: parseInt(unit.value),
          fournisseurs: selectedFournisseurs
        }
      }
      const response = await put(
        `products/${id}`,
        productInfos
      );
      if(response.status === 200){
        navigate("/products")
      } 
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Produit" breadcrumbItem="Modifier" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>Informations du produit</CardTitle>
                  <p className="card-title-desc mb-4">
                    Remplir le formulaire ci-dessous pour ajouter un nouveau produit
                  </p>

                  <Form onSubmit={handleProductSubmit}>
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="productname">Nom du produit*</Label>
                          <Input
                            id="productname"
                            name="productname"
                            type="text"
                            className="form-control"
                            placeholder="Nom du produit"
                            defaultValue={productName}
                            onChange={handleName}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="manufacturername">
                            Prix de vente unitaire*
                          </Label>
                          <Input
                            id="manufacturername"
                            name="manufacturername"
                            type="number"
                            className="form-control"
                            placeholder="Prix de vente unitaire"
                            min="1"
                            defaultValue={productPrice}
                            onChange={handleUnitprice}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="price">Quantité/Nombre*</Label>
                          <Input
                            id="price"
                            name="price"
                            type="number"
                            className="form-control"
                            placeholder="quantité ou nombre"
                            defaultValue={productQuantity}
                            onChange={handleQuantity}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="price">Prix en gros du stock*</Label>
                          <Input
                            id="price"
                            name="price"
                            type="number"
                            className="form-control"
                            placeholder="Prix en gros du stock"
                            defaultValue={productBuyingPrice}
                            onChange={handleBuyingPrice}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label className="control-label">
                            Fournisseurs
                          </label>
                          <Select
                            value={selectedMulti}
                            isMulti={true}
                            onChange={handleMulti}
                            options={fournisseurs}
                            className="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="price">Image du produit</Label>
                          <Input
                            id="price"
                            name="price"
                            type="file"
                            className="form-control"
                            placeholder="quantité ou nombre"
                            accept="image/*"
                            onChange={handleFileChange}
                          />
                        </div>
                        <div className="mb-3">
                          <Label className="control-label">Unité de vente[kg(kilogramme)/m(mètre)/l(litre)]*</Label>
                          <Select
                            value={unit}
                            className="select2"
                            placeholder="Select"
                            options={unities}
                            onChange={handleUnit}
                            required
                          />
                        </div>
                        
                        <div className="mb-3">
                          <Label htmlFor="productdesc">
                            Description du produit (facultatif)
                          </Label>
                          <textarea
                            className="form-control mb-3"
                            id="productdesc"
                            rows="5"
                            defaultValue={productDescription}
                            placeholder="Product Description"
                            onChange={handleDescription}
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary" className="btn ">
                        Ajouter
                      </Button>
                      <Button type="submit" color="secondary" className=" ">
                        Annuler
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditProduct
