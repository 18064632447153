import React, { useEffect, useRef, forwardRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import { isEmpty, map } from "lodash";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { get } from "helpers/apiServices";
import { useReactToPrint } from 'react-to-print';
import PrintableInvoice from "./PrintableInvoice";


const InvoiceDetail = props => {
  const [articles, setArticles] = useState([]);
  const [client, setClient] = useState();
  const [total, setTotal] = useState();
  const [creationDate, setCreationDate] = useState(null);
  const [entreprise, setEntreprise] = useState(null);
    
  const componentToPrint = useRef();

  //meta title
  document.title = "Details facture | BD International Trading";

  const dispatch = useDispatch();

  let { id } = useParams();

  const params = props.router.params;
  const [isFacture, setIdFacture] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get(`bills/${id}`);
        const responseData = response.data;
        setCreationDate(responseData?.created_date? new Date(responseData?.created_date).toLocaleDateString(): null)
        setArticles(responseData.products);
        setClient(responseData.client);
        setTotal(responseData?.total)
        setEntreprise(responseData?.user?.entreprise)
        setIdFacture(responseData?.id)
      } catch (error) {
        console.error('Error getting products:', error);
        // Gérer l'erreur ici
      }
    };
    fetchData();
  }, [])

  //Print the Invoice
  const printInvoice = useReactToPrint({
    content: () => componentToPrint.current
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Ventes" breadcrumbItem="Détails Facture" url={`/factures`} />
          
          <PrintableInvoice
            ref={componentToPrint}
            creationDate={creationDate}
            articles={articles}
            client={client}
            total={total}
            printInvoice={printInvoice}
            isFacture={isFacture}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

InvoiceDetail.propTypes = {
  match: PropTypes.any,
};

export default withRouter(InvoiceDetail);
