import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
  FormFeedback,
  Label,
  Form,
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { Link } from 'react-router-dom';
import "flatpickr/dist/themes/material_blue.css";
import FlatPickr from "react-flatpickr";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../components/Common/DeleteModal";
import {
  getCustomers as onGetCustomers,
  addNewCustomer as onAddNewCustomer,
  updateCustomer as onUpdateCustomer,
  deleteCustomer as onDeleteCustomer,
} from "store/e-commerce/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import TableContainer from '../../components/Common/TableContainer'

// Column
import {
  UserName,
  Address,
  Rating,
  Phone,
  Email,
  Name
} from './ClientCol';
import moment from "moment";
import { PatternFormat } from "react-number-format";
import Spinners from "components/Common/Spinner";
import { toast, ToastContainer } from "react-toastify";
import { get, post, put, remove } from "helpers/apiServices";

const Customers = () => {

  //meta title
  document.title = "Clients | BD International Trading";

  const dispatch = useDispatch();

  const selectEcommerceState = (state) => state.ecommerce;
  const EcommerceCustomerProperties = createSelector(
    selectEcommerceState,
    (Ecommerce) => ({
      customers: Ecommerce.customers,
      loading: Ecommerce.loading
    })
  );

  const {
    customers, loading
  } = useSelector(EcommerceCustomerProperties);

  const [isLoading, setLoading] = useState(loading)

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get('clients/');
        setClients(response.data);
      } catch (error) {
        console.error('Error getting clients:', error);
        // Gérer l'erreur ici
      }
    };
    fetchData();
  }, []);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      fullname: (customer && customer.fullname) || '',
      phone: (customer && customer.phone) || '',
      email: (customer && customer.email) || '',
      address: (customer && customer.address) || '',
      cni: (customer && customer.cni) || '',
    },
    validationSchema: Yup.object({
      fullname: Yup.string().required("Please Enter Your Name"),
      phone: Yup.string().required("Please Enter Your Phone"),
      email: Yup.string().matches(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "Please Enter Valid Email"
      ).required("Please Enter Your Email"),
      address: Yup.string().required("Please Enter Your Address"),
      cni: Yup.string().required("Please Enter Your Wallet Balance"),
    }),
    onSubmit: async (values) => {
      if (isEdit) {
        const updateCustomer = {
          fullname: values.fullname,
          phone: values.phone,
          email: values.email,
          address: values.address,
          cni: values.cni,
        };
        // update customer
        
        
      try {
        const response = await put(`clients/${customer.id}`, updateCustomer);
        if(response?.status === 200){
          toast.success("Informations client mise à jour avec succès")
          try {
            const response1 = await get('clients/');
            setClients(response1.data);
          } catch (error) {
            console.error('Error getting clients:', error);
            // Gérer l'erreur ici
          }

        }
      } catch (error) {
        if(error?.response?.status === 403){
          toast.error(error?.response?.data?.details)
        }else{
          toast.error('Erreur rencontrée, merci de réessayer!');
        }
      }
        validation.resetForm();
      } else {
        const newCustomer = {
          fullname: values["fullname"],
          phone: values["phone"],
          email: values["email"],
          address: values["address"],
          cni: values["cni"],
        };
        
      try {
        const response = await post('clients/', newCustomer);
        if(response?.status === 201){
          toast.success("Client créé avec succès")
          try {
            const response1 = await get('clients/');
            setClients(response1.data);
          } catch (error) {
            console.error('Error getting clients:', error);
            
          }

        }
      } catch (error) {
        if(error?.response?.status === 403){
          toast.error(error?.response?.data?.details)
        }else{
          toast.error('Erreur rencontrée, merci de réessayer!');
        }
      }
        validation.resetForm();
      }
      toggle();
    },
  });

  const handleCustomerClick = arg => {
    const customer = arg;

    setCustomer({
      id: customer.id,
      fullname: customer.fullname,
      phone: customer.phone,
      email: customer.email,
      address: customer.address,
      cni: customer.cni,
    });

    setIsEdit(true);
    toggle();
  };

  // Customber Column
  const columns = useMemo(
    () => [

      {
        Header: '#',
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        }
      },
      {
        Header: 'Nom',
        accessor: 'fullname',
        filterable: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },
      {
        Header: 'Email',
        accessor: 'email',
        filterable: true,
        Cell: (cellProps) => {
          return <Email {...cellProps} />;
        }
      },
      {
        Header: 'Phone',
        accessor: 'phone',
        filterable: true,
        Cell: (cellProps) => {
          return <Phone {...cellProps} />;
        }
      },
      {
        Header: 'Address',
        accessor: 'address',
        filterable: true,
        Cell: (cellProps) => {
          return <Address {...cellProps} />;
        }
      },
      {
        Header: 'Achats',
        accessor: 'bills',
        filterable: true,
        Cell: (cellProps) => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.bills.length}
              </h5>
            </>
          )
        }
      },
      {
        Header: 'Action',
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  onClick={() => {
                    const customerData = cellProps.row.original;
                    handleCustomerClick(customerData);
                  }
                  }
                >
                  <i className="mdi mdi-pencil font-size-16 text-success me-1" id="edittooltip"></i>
                  Modifier
                  <UncontrolledTooltip placement="top" target="edittooltip">
                  Modifier
                  </UncontrolledTooltip>
                </DropdownItem>

                <DropdownItem
                  onClick={() => {
                    const customerData = cellProps.row.original;
                    onClickDelete(customerData);
                  }}>
                  <i className="mdi mdi-trash-can font-size-16 text-danger me-1" id="deletetooltip"></i>
                  Supprimer
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                  Supprimer
                  </UncontrolledTooltip>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        }
      },
    ],
    []
  );

  const toggle = () => {
    if (modal) {
      setModal(false);
      setCustomer(null);
    } else {
      setModal(true);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (customerToDelete) => {
    setCustomer(customerToDelete);
    setDeleteModal(true);
  };
  const handleDeleteClient = async () => {
    
    if (customer && customer?.id) {
      try {
        const response = await remove(`/clients/${customer?.id}`);
        setDeleteModal(false);
        if(response.status === 204){
          const allClients = await get('clients/');
          setClients(allClients?.data);
          toast.success("Client supprimé avec succès", { autoClose: 2000 });
        }
      } catch (error) {
        if(error?.response?.status === 403 || error?.response?.status === 404){
          toast.error(error?.response?.data?.details)
        }else{
          toast.error('Une erreur est survenue lors de la suppression de la facture')
          console.error('Error', error);
        }
      }
    }
    setDeleteModal(false);
  };


  const handleDeleteCustomer = () => {
    if (customer && customer.id) {
      dispatch(onDeleteCustomer(customer.id));
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    if (customers && !customers.length) {
      dispatch(onGetCustomers());
    }
  }, [dispatch, customers]);

  const handleCustomerClicks = () => {
    setIsEdit(false);
    toggle();
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteClient}
        onCloseClick={() => setDeleteModal(false)}
        message = {'Voulez-vous vraiment supprimer ce client?'}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Liste" breadcrumbItem="Gestion clientèle" />
          <Row>
            {
              isLoading ? <Spinners setLoading={setLoading} />
                :
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <TableContainer
                        columns={columns}
                        data={clients}
                        isGlobalFilter={true}
                        isAddCustList={true}
                        isPagination={true}
                        handleCustomerClick={handleCustomerClicks}
                        customPageSize={8}
                        tableClass="align-middle table-nowrap"
                        isShowingPageLength={false}
                        iscustomPageSizeOptions={true}
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                      />
                    </CardBody>
                  </Card>
                </Col>
            }
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit
                ? "Edit Customer"
                : "Nouveau client"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>Nom complet ou Dénomination</Label>
                      <Input
                        name="fullname"
                        type="text"
                        placeholder="Nom complet de la personne ou de l'entreprise cliente"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.fullname || ""}
                        invalid={
                          validation.touched.fullname && validation.errors.fullname ? true : false
                        }
                      />
                      {validation.touched.fullname && validation.errors.fullname ? (
                        <FormFeedback type="invalid">{validation.errors.fullname}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label>Téléphone</Label>
                      <PatternFormat
                        className="form-control"
                        name="phone"
                        placeholder="Ajouter numéro téléphone du client(e)"
                        value={validation.values.phone || ""}
                        onChange={validation.handleChange}
                        format="+### ## ### ## ##"
                      />

                      {validation.touched.phone && validation.errors.phone ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.phone}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label>Email</Label>
                      <Input
                        name="email"
                        type="email"
                        placeholder="Ajouter email du client(e)"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={
                          validation.touched.email && validation.errors.email ? true : false
                        }
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label>Adresse</Label>
                      <Input
                        name="address"
                        type="textarea"
                        placeholder="Ajouter adresse du client(e)"
                        rows="3"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.address || ""}
                        invalid={
                          validation.touched.address && validation.errors.address ? true : false
                        }
                      />
                      {validation.touched.address && validation.errors.address ? (
                        <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                      ) : null}
                    </div>


                    <div className="mb-3">
                      <Label>Numéro pièce d'identification / Code postal</Label>
                      <Input
                        name="cni"
                        type="text"
                        placeholder="Ajouter numéro d'identification / Code postal"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.cni || ""}
                        invalid={
                          validation.touched.cni && validation.errors.cni ? true : false
                        }
                      />
                      {validation.touched.cni && validation.errors.cni ? (
                        <FormFeedback type="invalid">{validation.errors.cni}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-customer"
                      >
                        Soumettre
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

Customers.propTypes = {
  customers: PropTypes.array,
  onGetCustomers: PropTypes.func,
  onAddNewCustomer: PropTypes.func,
  onDeleteCustomer: PropTypes.func,
  onUpdateCustomer: PropTypes.func,
};

export default Customers;
