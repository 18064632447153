import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import Select from "react-select";
import Dropzone from "react-dropzone";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { post, get } from "helpers/apiServices";
import convertImageToBase64 from "helpers/convertToBase64";
import { toast } from "react-toastify";

const AddProduct = () => {
  const [productName, setProductName] = useState();
  const [productPrice, setProductPrice] = useState();
  const [productBuyingPrice, setProductBuyingPrice] = useState();
  const [productQuantity, setProductQuantity] = useState(0);
  const [productDescription, setProductDescription] = useState(null);
  const [selectedMulti, setselectedMulti] = useState([]);
  const [unit, setUnit] = useState();
  const navigate = useNavigate();
  const [fournisseurs, setFournisseurs] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedFournisseurs, setSelectedFournisseurs] = useState([]);
  const [image, setImage] = useState(null);
  const [extension, setExtension] = useState(null);
  const [convertedImage, setConvertedImage] = useState(null);

  const [unities, setUnities] = useState([])
  //meta title
  document.title = "Nouveau produit | BD International Trading";


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get('/products/unities/');
        if(response.status === 200){  
          let allUnities = response?.data;     
          let objectTab = allUnities.map((unity) => ({
            value: unity.id,
            label: unity.libelle
          }));
          setUnities(objectTab);
        }
      } catch (error) {
        console.error('Error while getting fournisseurs:', error);
        // Gérer l'erreur ici
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get('fournisseurs/');
        if(response.status === 200){  
          let allFournisseurs = response?.data;     
          let objectTab = allFournisseurs.map((fournisseur) => ({
            value: fournisseur.id,
            label: fournisseur.fullname
          }));
          setFournisseurs(objectTab);
        }
      } catch (error) {
        console.error('Error while getting fournisseurs:', error);
        // Gérer l'erreur ici
      }
    };
    fetchData();
  }, []);




  const handleName = (e) =>{
    setProductName(e.target.value)
  }
  const handleUnit = (e) =>{
    setUnit(e.value)
  }
  const handleUnitprice = (e) =>{
    setProductPrice(e.target.value)
  }
  const handleBuyingPrice = (e) =>{
    setProductBuyingPrice(e.target.value)
  }
  const handleQuantity = (e) =>{
    setProductQuantity(e.target.value)
  }
  const handleDescription = (e) =>{
    setProductDescription(e.target.value)
  }

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (file){
        setImage(file)
        const image64 = await convertImageToBase64(file)
        setConvertedImage(image64)
        
        const fileName = file.name;
    
        const fileSegments = fileName.split('.');
        
        const fileExtension = fileSegments[fileSegments.length - 1];
        setExtension(fileExtension)
    }
    
  };
  
  function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti);
    const fournisseursTab = []
    selectedMulti.map(selectedfournisseur => {
      if(selectedfournisseur){
        fournisseursTab.push(selectedfournisseur?.value);
      }
    });
    setSelectedFournisseurs(fournisseursTab);
  }

  const handleCity = (e) =>{
    setSelectedCity(e.value)
  }

  const handleCategory = (e) =>{
    setSelectedCategory(e.value)
  }

  const handleProductSubmit = async (e) =>{
    e.preventDefault();
    try {
      let productInfos = null
      if(convertedImage){
        productInfos = {
          title: productName,
          description: productDescription,
          quantity: parseInt(productQuantity),
          price: parseFloat(productPrice),
          prix_gros: parseFloat(productBuyingPrice),
          unity: parseInt(unit),
          fournisseurs: selectedFournisseurs,
          image: {
            base64: convertedImage,
            extension: extension
          }
        }
      }else{
        
        productInfos = {
          title: productName,
          description: productDescription,
          quantity: parseInt(productQuantity),
          price: parseFloat(productPrice),
          prix_gros: parseFloat(productBuyingPrice),
          unity: parseInt(unit),
          fournisseurs: selectedFournisseurs,
        }
      }
      const response = await post(
        'products/',
        productInfos
      );
      if(response.status === 201){
        navigate("/products")
      } 
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Produit" breadcrumbItem="Ajouter" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>Informations du produit</CardTitle>
                  <p className="card-title-desc mb-4">
                    Remplir le formulaire ci-dessous pour ajouter un nouveau produit
                  </p>

                  <Form onSubmit={handleProductSubmit}>
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="productname">Nom du produit*</Label>
                          <Input
                            id="productname"
                            name="productname"
                            type="text"
                            className="form-control"
                            placeholder="Nom du produit"
                            onChange={handleName}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="manufacturername">
                            Prix de vente unitaire*
                          </Label>
                          <Input
                            id="manufacturername"
                            name="manufacturername"
                            type="number"
                            className="form-control"
                            placeholder="Prix de vente unitaire"
                            min="1"
                            onChange={handleUnitprice}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="price">Quantité/Nombre*</Label>
                          <Input
                            id="price"
                            name="price"
                            type="number"
                            className="form-control"
                            placeholder="quantité ou nombre"
                            onChange={handleQuantity}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="price">Prix en gros du stock*</Label>
                          <Input
                            id="price"
                            name="price"
                            type="number"
                            className="form-control"
                            placeholder="Prix en gros du stock"
                            onChange={handleBuyingPrice}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label className="control-label">
                            Fournisseurs
                          </label>
                          <Select
                            value={selectedMulti}
                            isMulti={true}
                            onChange={handleMulti}
                            options={fournisseurs}
                            className="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="price">Image du produit</Label>
                          <Input
                            id="price"
                            name="price"
                            type="file"
                            className="form-control"
                            placeholder="quantité ou nombre"
                            accept="image/*"
                            onChange={handleFileChange}
                          />
                        </div>
                        <div className="mb-3">
                          <Label className="control-label">Unité de vente[kg(kilogramme)/m(mètre)/l(litre)]*</Label>
                          <Select
                            className="select2"
                            placeholder="Select"
                            options={unities}
                            onChange={handleUnit}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="productdesc">
                            Description du produit (facultatif)
                          </Label>
                          <textarea
                            className="form-control mb-3"
                            id="productdesc"
                            rows="5"
                            placeholder="Product Description"
                            onChange={handleDescription}
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary" className="btn ">
                        Ajouter
                      </Button>
                      <Button type="submit" color="secondary" className=" ">
                        Annuler
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
{/* 
              <Card>
                <CardBody>
                  <CardTitle className="mb-3">Product Images</CardTitle>
                  <Form>
                    <Dropzone
                      onDrop={acceptedFiles => {
                        handleAcceptedFiles(acceptedFiles)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="dz-message needsclick">
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        )
                      })}
                    </div>
                  </Form>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle>Meta Data</CardTitle>
                  <p className="card-title-desc">
                    Fill all information below
                  </p>

                  <Form onSubmit={(e) => e.preventDefault()}>
                    <Row>
                      <Col sm={6}>
                        <div className="mb-3">
                          <Label htmlFor="metatitle">Meta title</Label>
                          <Input
                            id="metatitle"
                            name="productname"
                            type="text"
                            className="form-control"
                            placeholder="Metatitle"
                          />
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="metakeywords">Meta Keywords</Label>
                          <Input
                            id="metakeywords"
                            name="manufacturername"
                            type="text"
                            className="form-control"
                            placeholder="Meta Keywords"
                          />
                        </div>
                      </Col>

                      <Col sm={6}>
                        <div className="mb-3">
                          <Label htmlFor="metadescription">
                            Meta Description
                          </Label>
                          <textarea
                            className="form-control"
                            id="metadescription"
                            rows="5"
                            placeholder="Meta Description"
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" className="btn btn-primary waves-effect waves-light" color="primary">
                        Save Changes
                      </Button>
                      <Button type="submit" className="btn btn-secondary waves-effect waves-light" color="secondary">
                        Cancel
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddProduct
